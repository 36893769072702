import { render, staticRenderFns } from "./Expire.vue?vue&type=template&id=54775a4f&"
import script from "./Expire.vue?vue&type=script&lang=js&"
export * from "./Expire.vue?vue&type=script&lang=js&"
import style0 from "./Expire.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports